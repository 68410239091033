<template>
  <div>
    <el-row>
      <el-col :span="21">
        <el-input
          type="textarea"
          v-model="orderNoOrCjm"
          placeholder="请输入采集码"
          @blur="handleQuery"
          :rows="3"
        ></el-input>
      </el-col>
      <el-col :span="3">
        <div style="padding-left: 20px">
          <el-button type="primary" @click="handleQuery">查询订单</el-button>
        </div>
      </el-col>
    </el-row>
    <div style="padding-top: 20px">
      <el-table :data="dataList" stripe border height="100%" class="schools">
        <el-table-column fixed prop="orderNo" label="订单号"> </el-table-column>
        <el-table-column prop="studentName" label="名字" align="center">
        </el-table-column>
        <el-table-column prop="studentCode" label="学号">
          <template slot-scope="scope">
            {{ scope.row.educationalInfo.studentCode }}
          </template>
        </el-table-column>
        <el-table-column prop="paymentTime" label="付款时间" align="center">
        </el-table-column>
        <el-table-column prop="status" label="付款状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">下单</span>
            <span v-if="scope.row.status == 1">预付款</span>
            <span v-if="scope.row.status == 2">完成</span>
            <span v-if="scope.row.status == 3">取消</span>
            <span v-if="scope.row.status == 5">取消中</span>
          </template>
        </el-table-column>
        <el-table-column prop="shotPrice" label="付款金额" align="center">
        </el-table-column>
        <el-table-column prop="payType" label="付款方式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payType == 0">线下</span>
            <span v-if="scope.row.payType == 1">e支付</span>
            <span v-if="scope.row.payType == 2">微信支付</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getOrderByCjm } from "@/api/order";
export default {
  name: "sOrderList",
  components: {},
  data() {
    return {
      orderNoOrCjm: "",
      dataList: [],
    };
  },
  methods: {
    async handleQuery() {
      const that = this;
      const orderNoOrCjm = that.orderNoOrCjm;
      const list = await getOrderByCjm({ orderNoOrCjm: orderNoOrCjm });
      if (list.code == 0) {
        that.dataList = [list.data];
      }
    },
  },
};
</script>
